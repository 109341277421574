import { useCallback, useMemo, useState } from 'react';

import { Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { flagValues } from '@ecp/utils/flags';

import { AutopayModal, ManageAutopayCard } from '@ecp/features/servicing/autopay';
import { CaMileageModal } from '@ecp/features/servicing/ca-mileage';
import { PaymentClassicModal, PaymentModal } from '@ecp/features/servicing/payments';
import { EnrollPaperlessCard } from '@ecp/features/servicing/preferences';
import { ContactUsMicro, DashboardCard, Section } from '@ecp/features/servicing/shared/components';
import type { EnrollmentNavState } from '@ecp/features/servicing/shared/routing';
import { navState } from '@ecp/features/servicing/shared/routing';
import {
  getProductLineFromPolicyResponse,
  isInPendingCancelStatus,
  useBookRollPolicies,
  usePaperless,
  usePaymentInfoForAllPolicies,
  useUserPolicies,
} from '@ecp/features/servicing/shared/state';
import { getBrandData } from '@ecp/features/servicing/shared/util';
import { IconUIWave } from '@ecp/themes/base';

import {
  AlertsSection,
  BookRollInfoModal,
  PaymentsDueSection,
  PoliciesSection,
  StartYourQuote,
} from '../../components';
import { useStyles } from './DashboardPage.styles';

export const DashboardPage: React.FC = () => {
  const { classes } = useStyles();
  const location = useLocation();

  const enrollmentNavState = location.state as EnrollmentNavState;
  const fromEnrollment = enrollmentNavState?.from === navState.from;

  const { allPoliciesAreInactive, isAnyActivePolicyNotAllowingPaperless } = usePaperless({
    throwOnError: false,
  });

  const {
    user,
    policies,
    isLoading: isLoadingUserPolicies,
    isError: isErrorUserPolicies,
  } = useUserPolicies({ throwOnPolicyError: false });
  const { paymentInfos, isLoading: isLoadingPaymentInfos } = usePaymentInfoForAllPolicies({
    throwOnError: false,
  });

  const { bookRolledConnectPolicies, isLoading: isLoadingBookRollPolicies } = useBookRollPolicies();
  const inForceBookRolledPolicies = bookRolledConnectPolicies?.filter(
    (bookrolledPolicy) => bookrolledPolicy.policyData.policy.status === 'In Force',
  );

  const customerServicePhone = getBrandData(
    inForceBookRolledPolicies?.at(0)?.policyData,
  )?.mainCustServPhoneNum;

  const [bookRolledModalOpen, setBookRolledModalOpen] = useState(false);
  const handleBookRollLearnMoreClick = useCallback(() => setBookRolledModalOpen(true), []);
  const handleBookRollModalClose = useCallback(() => setBookRolledModalOpen(false), []);

  const showPaymentsDueSection =
    isLoadingPaymentInfos ||
    paymentInfos?.some((paymentInfo) => paymentInfo.canMakePayment) ||
    paymentInfos?.some(
      (paymentInfo) =>
        paymentInfo.isClassicPolicy && paymentInfo.isClassicBillingAccountStatusInPendingCancel,
    ) ||
    paymentInfos?.every((paymentInfo) => !paymentInfo.isClassicPolicy);

  const showManageAutopayClassic = useMemo(() => {
    const isAutopayClassicFeatureEnabled = flagValues.AUTOPAY_CLASSIC;

    const isAllPoliciesClassic = paymentInfos?.every(
      (paymentInfo) => paymentInfo.isClassicPolicy === true,
    );
    const hasAutopayDisabledAndNotPendingCancel = paymentInfos?.some(
      (paymentInfo) =>
        paymentInfo.isAutopayEnabled === false &&
        !isInPendingCancelStatus({
          billingResponse: paymentInfo.billingAccount,
          productLine: getProductLineFromPolicyResponse(paymentInfo.policyData),
          policySource: paymentInfo.policyData?.policy.sourceSystemName,
          policyData: paymentInfo.policyData,
        }),
    );

    return (
      isAllPoliciesClassic &&
      hasAutopayDisabledAndNotPendingCancel &&
      !isAutopayClassicFeatureEnabled
    );
  }, [paymentInfos]);

  const showEnrollPaperlessCard = !(
    isAnyActivePolicyNotAllowingPaperless || allPoliciesAreInactive
  );

  const header = (
    <>
      {!isLoadingUserPolicies && !user?.policyNumbers?.length && <StartYourQuote />}
      <Stack spacing={1.5} direction='row'>
        <h1>{fromEnrollment ? 'Welcome' : 'Welcome back'}</h1>
        <IconUIWave className={classes.icon} />
      </Stack>
    </>
  );

  const preferencesSection = (showEnrollPaperlessCard || showManageAutopayClassic) && (
    <Section title='Policy options'>
      <Stack direction={{ xs: 'column', md: 'row', lg: 'column' }} spacing={3}>
        {showManageAutopayClassic && <ManageAutopayCard />}
        {showEnrollPaperlessCard && (
          <EnrollPaperlessCard isErrorUserPolicies={isErrorUserPolicies} />
        )}
      </Stack>
    </Section>
  );

  const secondaryContent = (
    <Stack spacing={5} className={classes.secondaryContent}>
      {preferencesSection}
      <ContactUsMicro
        classes={{
          body: classes.contactUsBody,
          button: classes.contactUsButton,
          divider: classes.contactUsDivider,
        }}
      />
    </Stack>
  );

  const body = (
    <>
      <Stack spacing={5}>
        <Stack className={classes.root} spacing={5}>
          {!isLoadingBookRollPolicies && !isLoadingUserPolicies && (
            <AlertsSection
              bookRolledConnectPolicies={bookRolledConnectPolicies}
              policies={policies}
              handleBookRollLearnMoreClick={handleBookRollLearnMoreClick}
            />
          )}
          {showPaymentsDueSection && (
            <Section title='Payments due soon'>
              <PaymentsDueSection />
            </Section>
          )}
          {!!user?.policyNumbers.length && <PoliciesSection />}
        </Stack>
      </Stack>
      <PaymentModal />
      <PaymentClassicModal />
      <AutopayModal />
      <CaMileageModal />
      <BookRollInfoModal
        open={bookRolledModalOpen}
        onClose={handleBookRollModalClose}
        number={customerServicePhone}
        onButtonClick={handleBookRollModalClose}
      />
    </>
  );

  return (
    <DashboardCard
      header={header}
      secondaryContent={secondaryContent}
      secondaryContentAlign='children'
    >
      {body}
    </DashboardCard>
  );
};
