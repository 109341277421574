import { useCallback, useMemo } from 'react';

import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { emptyArray, isTruthy } from '@ecp/utils/common';
import { DateConstants, formatDate } from '@ecp/utils/date';
import { flagValues } from '@ecp/utils/flags';
import { datadogLog } from '@ecp/utils/logger';

import { env } from '@ecp/env';
import type { ModalFlow, ModalStep } from '@ecp/features/servicing/shared/routing';
import type {
  AutopayInformation,
  PaymentMethod,
  PolicyTypeCode,
  UiPolicy,
} from '@ecp/features/servicing/shared/types';

import type { BillingPlan, BillingResponse } from '../billing';
import { useBillingAccount } from '../billing';
import { QUERY_KEYS } from '../constants';
import { usePolicy, useUserPolicies } from '../policy';
import type { ResponseObject, ServicingRequestError } from '../servicingRequest';
import { useSharedState } from '../sharedState';
import {
  getClassicBillingAccountStatusInPendingCancel,
  getProductLineFromPolicyResponse,
  isBillingAccountStatusInCancellation,
  isInPendingCancelStatus,
  isLegacyPolicy,
  isPaymentOn,
  shouldMakePaymentOptionsCall,
} from '../util';
import { useAllBillingAccounts } from './../billing/state';
import {
  autoPayEnroll,
  autopayUnenroll,
  createPaymentMethod,
  deletePaymentMethod,
  getPaymentOptions,
  getPaymentUrl,
  getPolicyPayments,
  makePaymentWithExistingMethod,
  makePaymentWithNewMethod,
  payeezyTokenizeCreditCard,
} from './api';
import { CARD_TYPE, PAYEEZY_CARD_TYPE, PAYMENT_TYPE } from './constants';
import type {
  AutopayEnrollError,
  AutopayEnrollRequest,
  AutopayEnrollResponse,
  AutopayEnrollSuccess,
  AutopayUnenrollError,
  AutopayUnenrollResponse,
  AutopayUnenrollSuccess,
  CardRequestBody,
  CreatePaymentMethod,
  CreatePaymentMethodError,
  CreatePaymentMethodRequest,
  CreatePaymentMethodResponse,
  CreatePaymentMethodSuccess,
  DeleteAllPaymentMethods,
  DeleteAllPaymentMethodsResponse,
  DeletePaymentError,
  EftRequestBody,
  GetAmountDueNDueDateInfoReturn,
  GetIsAutopayEligibleReturn,
  GetPayeezyToken,
  GetPaymentInfoReturn,
  GetPaymentMethodRequest,
  GetPaymentOptionsResponse,
  GetPaymentOptionsSuccess,
  GetPaymentsResponse,
  GetPaymentsSuccess,
  GetPaymentUrlResponse,
  GetPolicyPaymentsRequest,
  MakePaymentWithExistingMethod,
  MakePaymentWithExistingMethodError,
  MakePaymentWithExistingMethodRequest,
  MakePaymentWithExistingMethodSuccess,
  MakePaymentWithNewMethodRequest,
  PayeezyCreditCard,
  PoliciesPaymentOptionsResponse,
  PolicyPaymentResponse,
  UseAmountDueNDueDateInfoReturn,
  UseIsAutopayElibibleForPaymentMethodReturn as UseIsAutopayEligibleForPaymentMethodReturn,
  UseIsAutopayEligibleReturn,
  UseIsAutopayEnabledReturn,
  UsePaymentInfoForAllPoliciesReturn,
  UsePaymentInfoReturn,
} from './types';

export const useDeleteAllPaymentMethods = (): DeleteAllPaymentMethodsResponse<
  DeleteAllPaymentMethods,
  ResponseObject<boolean, DeletePaymentError>
> => {
  const {
    mutateAsync,
    isPending: isSubmitting,
    isError,
  } = useMutation({
    mutationFn: async (props: DeleteAllPaymentMethods) => {
      const { policyPayments, policyNumber, policySource } = props;
      let deletePaymentMethodsError: DeletePaymentError | undefined;

      policyPayments &&
        (await Promise.all(
          policyPayments.map(async (paymentOption) => {
            let primeKey;
            let paymentMethodType;
            if (paymentOption.type === 'CC') {
              primeKey = paymentOption.ccprimekey;
              paymentMethodType = paymentOption.type;
            } else if (paymentOption.type === 'EFT') {
              primeKey = paymentOption.efmprimekey;
              paymentMethodType = paymentOption.type;
            }
            if (paymentOption.type && policyNumber && primeKey && paymentMethodType) {
              const expectedStatuses = [400] as const;
              await deletePaymentMethod(
                {
                  policyNumber,
                  paymentMethodType,
                  primeKey,
                  policySource,
                },
                { dontLogErrorsForStatus: expectedStatuses },
              ).catch((error: ServicingRequestError) => {
                const errorStatus = error.response?.status as (typeof expectedStatuses)[number];
                if (errorStatus === 400) {
                  deletePaymentMethodsError = 'Invalid Request';
                } else {
                  deletePaymentMethodsError = 'Unknown error';
                }
              });
            }
          }),
        ));
      const response: ResponseObject<boolean, DeletePaymentError> = {
        success: !deletePaymentMethodsError,
        error: deletePaymentMethodsError,
      };

      return response;
    },
  });

  return {
    deleteAllPaymentMethods: mutateAsync,
    isLoading: false,
    isError,
    isSubmitting,
  };
};

export const usePayeezyTokenizeCreditCard = (): GetPayeezyToken<
  PayeezyCreditCard,
  string | undefined
> => {
  const {
    mutateAsync,
    isPending: isSubmitting,
    isError,
  } = useMutation({
    mutationFn: async (request: PayeezyCreditCard) => {
      return payeezyTokenizeCreditCard(request);
    },
  });

  return {
    getFinancialAccountToken: mutateAsync,
    isLoading: false,
    isError,
    isSubmitting,
  };
};

export const useCreatePaymentMethod = (): CreatePaymentMethodResponse<
  CreatePaymentMethod,
  ResponseObject<CreatePaymentMethodSuccess, CreatePaymentMethodError>
> => {
  const { getFinancialAccountToken, isSubmitting: isSubmittingPayeezy } =
    usePayeezyTokenizeCreditCard();
  const { deleteAllPaymentMethods } = useDeleteAllPaymentMethods();

  const {
    mutateAsync,
    isPending: isSubmitting,
    isError,
  } = useMutation({
    mutationFn: async (props: CreatePaymentMethod) => {
      const { email, policyNumber, paymentType, policySource, paymentOptions } = props;
      let tokenDetails!: CardRequestBody | EftRequestBody;
      let success: CreatePaymentMethodSuccess | undefined;
      let addPaymentMethodError: CreatePaymentMethodError | undefined;

      if (props.paymentType === PAYMENT_TYPE.CC) {
        const { cardExpirationDate, cardNumber, cardType, fullName } = props;
        const date = dayjs(cardExpirationDate);
        await getFinancialAccountToken({
          policyNumber,
          cardNumber,
          fullName,
          cardExpirationDate,
          cardType: PAYEEZY_CARD_TYPE[cardType],
        }).then((response) => {
          if (response)
            tokenDetails = {
              card: {
                financialAccountToken: response,
                cardType: CARD_TYPE[cardType],
                expirationMonth: date.format('MM'),
                expirationYear: date.format('YYYY'),
              },
            };
        });
      } else {
        const { routingNumber, accountNumber } = props;
        tokenDetails = {
          ach: {
            routingNumber,
            accountNumber,
          },
        };
      }

      if (props.paymentType === PAYMENT_TYPE.CC && !tokenDetails)
        return {
          success: undefined,
          error: 'Error fetching Payeezy CreditCardToken',
        } as ResponseObject<CreatePaymentMethodSuccess, CreatePaymentMethodError>;

      const requestBody: CreatePaymentMethodRequest<CardRequestBody | EftRequestBody> = {
        policyNumber,
        policySource,
        paymentMethodType: paymentType === PAYMENT_TYPE.EFT ? 'EFT' : 'CC',
        nickName: paymentType === PAYMENT_TYPE.EFT ? 'Checking Account' : 'Credit Card',
        emailAddress: email,
        tokenDetails,
        auditInfo: {
          requestedDateTime: dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          requestedBy: email,
          requestingSystem: env.static.sourceId,
        },
      };

      // TODO: Update if condition when we will know the E1P Auto sourceSystemName
      if (!['HRSN_Horison', 'CAHP_Connect_Phoenix'].includes(policySource))
        await deleteAllPaymentMethods({
          policyNumber,
          policyPayments: paymentOptions,
          policySource,
        });

      const expectedStatuses = [400] as const;
      await createPaymentMethod(requestBody, { dontLogErrorsForStatus: expectedStatuses })
        .then(() => (success = true))
        .catch((error: ServicingRequestError) => {
          const errorStatus = error.errorStack?.status?.code as (typeof expectedStatuses)[number];
          if (errorStatus === 400) {
            addPaymentMethodError = 'Invalid Request';
          } else {
            addPaymentMethodError = 'Unknown error';
          }
          success = undefined;
        });

      const response: ResponseObject<CreatePaymentMethodSuccess, CreatePaymentMethodError> = {
        success,
        error: addPaymentMethodError,
      };

      return response;
    },
  });

  return {
    createPaymentMethod: mutateAsync,
    isLoading: false,
    isError,
    isSubmitting: isSubmitting || isSubmittingPayeezy,
  };
};

export const usePaymentOptions = (
  request: GetPaymentMethodRequest,
  disableQuery = false,
  throwOnError = true,
): GetPaymentOptionsResponse => {
  const queryClient = useQueryClient();

  const enabled = !!request.billingAccountNumber && !!request.policySource && !disableQuery;
  const queryKey = useMemo(
    () => [QUERY_KEYS.PAYMENT_OPTIONS, request.billingAccountNumber, request.policySource],
    [request.billingAccountNumber, request.policySource],
  );

  const { data, isLoading, isError, refetch, isFetching } = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      const { payload } = await getPaymentOptions(request);

      return payload;
    },
    enabled,
    throwOnError,
  });
  const clearCache = useCallback(
    (): void => queryClient.removeQueries({ queryKey }),
    [queryClient, queryKey],
  );

  return {
    isLoading,
    isError,
    paymentOptions: data?.paymentMethods,
    refetch,
    clearCache,
    isFetching,
  };
};

export const usePoliciesPaymentOptions = (
  requests: GetPaymentMethodRequest[],
  throwOnError = true,
): PoliciesPaymentOptionsResponse => {
  const queries = requests.map((request) => {
    return {
      queryKey: [QUERY_KEYS.PAYMENT_OPTIONS, request.billingAccountNumber, request.policySource],
      queryFn: () => getPaymentOptions(request).then((res) => res.payload),
      throwOnError,
    };
  });

  const results = useQueries({ queries });

  return useMemo(
    () => ({
      policiesPaymentsOptions: results.map((result) => result.data).filter(isTruthy),
      policiesPaymentsOptionsResults: results.map((result, index) => ({
        billingAccountNumber: requests[index].billingAccountNumber,
        paymentOptions: result.data,
        isError: result.isError,
        isFetching: result.isFetching,
        isLoading: result.isLoading,
      })),
      isLoading: results.some((result) => result.isLoading),
      isError: results.some((result) => result.isError),
      isFetching: results.some((result) => result.isFetching),
    }),
    [results, requests],
  );
};

export const usePolicyPayments = (request: GetPolicyPaymentsRequest): GetPaymentsResponse => {
  const {
    data = emptyArray as unknown as GetPaymentsSuccess,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [QUERY_KEYS.POLICY_PAYMENTS, request.policyNumber],
    queryFn: async () => {
      const { payload } = await getPolicyPayments(request);

      return payload;
    },
  });

  return {
    isLoading,
    isError,
    policyPayments: data,
  };
};

export const useMakePaymentWithExistingMethod = (): PolicyPaymentResponse<
  MakePaymentWithExistingMethodRequest,
  MakePaymentWithExistingMethod
> => {
  const {
    mutateAsync,
    isPending: isSubmitting,
    isError,
  } = useMutation({
    mutationFn: async (request: MakePaymentWithExistingMethodRequest) => {
      const { payload } = await makePaymentWithExistingMethod(request);

      return payload;
    },
  });

  return {
    makePayment: mutateAsync,
    isLoading: false,
    isError,
    isSubmitting,
  };
};

export const useAutopayEnroll = (): AutopayEnrollResponse<
  AutopayEnrollRequest,
  ResponseObject<AutopayEnrollSuccess, AutopayEnrollError>
> => {
  const {
    mutateAsync,
    isPending: isSubmitting,
    isError,
  } = useMutation({
    mutationFn: async (request: AutopayEnrollRequest) => {
      let autopayEnrollError: AutopayEnrollError | undefined = undefined;
      let success: AutopayEnrollSuccess | undefined;

      const expectedCodes = [400032, 400033, 400037] as const;
      await autoPayEnroll(request, { dontLogErrorsForErrorCode: expectedCodes })
        .then((res) => (success = res.payload))
        .catch((error: ServicingRequestError) => {
          const errorCode = error.errorStack?.status.messages[0]
            .code as (typeof expectedCodes)[number];
          switch (errorCode) {
            //  mortgagee billed policies
            case 400032:
              autopayEnrollError = 'HAS_MORTGAGEE';
              break;
            //  Enroll in autopay with a CC for a non-HO4 policy or a HO4 policy that’s not 12 pay
            case 400033:
              autopayEnrollError = 'NOT_12_PAY';
              break;
            // User must add a new payment method or choose an existing payment method to be the AutoPay.
            case 400037:
              autopayEnrollError = 'CANNOT_DELETE_ONLY_AUTOPAY_METHOD_ADD_ANOTHER_FIRST';
              break;
            default:
              autopayEnrollError = 'unknown';
          }
          success = undefined;
        });

      const response: ResponseObject<AutopayEnrollSuccess, AutopayEnrollError> = {
        success,
        error: autopayEnrollError,
      };

      return response;
    },
  });

  return {
    autoPay: mutateAsync,
    isLoading: false,
    isError,
    isSubmitting,
  };
};

export const useAutopayUnenroll = (): AutopayUnenrollResponse<
  AutopayEnrollRequest,
  ResponseObject<AutopayUnenrollSuccess, AutopayEnrollError>
> => {
  const {
    mutateAsync,
    isPending: isSubmitting,
    isError,
  } = useMutation({
    mutationFn: async (request: AutopayEnrollRequest) => {
      let autopayUnenrollError: AutopayUnenrollError | undefined = undefined;
      let success: AutopayUnenrollSuccess | undefined;
      await autopayUnenroll(request)
        .then((res) => (success = res.payload))
        .catch(() => {
          autopayUnenrollError = 'unknown';
          success = undefined;
        });

      const response: ResponseObject<AutopayUnenrollSuccess, AutopayEnrollError> = {
        success,
        error: autopayUnenrollError,
      };

      return response;
    },
  });

  return {
    autopayUnenroll: mutateAsync,
    isLoading: false,
    isError,
    isSubmitting,
  };
};

const getIsAutopayEnabled = (paymentOptions: GetPaymentOptionsSuccess | undefined): boolean =>
  (paymentOptions ?? []).some((option) => option.autopayEnrolled);

export const useIsAutopayEnabled = (
  policyNumber: string | undefined,
  throwOnError = true,
): UseIsAutopayEnabledReturn => {
  const {
    policyData,
    isError: isErrorPolicy,
    isLoading: isLoadingPolicy,
  } = usePolicy(policyNumber, { throwOnError });
  const {
    paymentOptions,
    isError: isErrorPaymentOptions,
    isLoading: isLoadingPaymentOptions,
  } = usePaymentOptions(
    {
      billingAccountNumber: policyData?.policy.billingAccountNumber,
      policySource: policyData?.policy.sourceSystemName,
    },
    !shouldMakePaymentOptionsCall(policyData),
    throwOnError,
  );

  return {
    isAutopayEnabled: getIsAutopayEnabled(paymentOptions),
    isError: isErrorPolicy || isErrorPaymentOptions,
    isLoading: isLoadingPolicy || isLoadingPaymentOptions,
  };
};

const validProductTypesForAutopay: PolicyTypeCode[] = ['HO3', 'HO4', 'HO6', 'HF9'];

const getIsAutopayEligible = (
  policyData: UiPolicy | undefined,
  paymentOptions: GetPaymentOptionsSuccess | undefined,
  billingAccount: BillingResponse | undefined,
): GetIsAutopayEligibleReturn => {
  const productLine = getProductLineFromPolicyResponse(policyData);
  const isClassicPolicy = isLegacyPolicy(productLine, policyData?.policy.sourceSystemName);

  const isPolicyInactive = policyData?.isInactive;
  const isEscrow = billingAccount?.billTo === 'Escrow';
  const policyType = policyData?.coverageOptions.at(0)?.policyTypeCode;
  const isPolicyTypeValid = validProductTypesForAutopay.includes(policyType);
  const isAutopayEnabled = getIsAutopayEnabled(paymentOptions);

  const isAutopayFeatureEnabled = !flagValues.DISABLE_AUTOPAY;
  const isAutopayClassicFeatureEnabled = flagValues.AUTOPAY_CLASSIC;

  const isPendingCancellation = isInPendingCancelStatus({
    billingResponse: billingAccount,
    productLine: getProductLineFromPolicyResponse(policyData),
    policySource: policyData?.policy.sourceSystemName,
  });

  const isBillPlanEligibleForAutopay = billingAccount?.billingPlan !== 'TWO-PAY PLAN';
  const isBillPlanEligibleForAutopayClassic = billingAccount?.billingPlan !== 'semi-annual';
  const isBillingResponseValidForClassic =
    billingAccount?.billTo &&
    billingAccount?.billingAccountStatus &&
    !!billingAccount?.dueDate &&
    billingAccount?.minimumDue !== undefined;

  const isNegativePremiumAmount =
    billingAccount &&
    ((billingAccount?.minimumDue !== undefined && billingAccount?.minimumDue < 0) ||
      (billingAccount?.fullPayBalance !== undefined && billingAccount?.fullPayBalance < 0));

  const isAutopayEligible =
    isAutopayFeatureEnabled &&
    !isAutopayEnabled &&
    !isEscrow &&
    !isPolicyInactive &&
    !isClassicPolicy &&
    isPolicyTypeValid &&
    isBillPlanEligibleForAutopay &&
    !isNegativePremiumAmount;

  const isAutopayEligibleClassic =
    !isAutopayEnabled &&
    isAutopayClassicFeatureEnabled &&
    !isEscrow &&
    !isPolicyInactive &&
    !isPendingCancellation &&
    isBillPlanEligibleForAutopayClassic &&
    isBillingResponseValidForClassic;

  const isManageAutopayEligible =
    isAutopayFeatureEnabled &&
    isAutopayEnabled &&
    !isEscrow &&
    !isPolicyInactive &&
    !isClassicPolicy &&
    isPolicyTypeValid &&
    isBillPlanEligibleForAutopay &&
    !isNegativePremiumAmount;

  // note intentionally not checking billing minimum due and is in cancelation billing. We know we cannot enable  autopay when a payment is needed,
  // but for our purposes, we just push them into a flow to pay first and then enable autopay (which takes care of  mim due and cancelation billing).
  // also, not checking pending automatic payments since we have no api. Can add here if/when api becomes available
  return {
    billingAccount,
    isAutopayEligible: !isClassicPolicy ? isAutopayEligible : isAutopayEligibleClassic,
    isAutopayEnabled,
    isBillPlanEligibleForAutopay,
    isClassicPolicy,
    isEscrow,
    isManageAutopayEligible,
    isPolicyTypeValid,
    paymentOptions,
    policyData,
  };
};

export const useIsAutopayEligible = (
  policyNumber: string | undefined,
  throwOnError = true,
): UseIsAutopayEligibleReturn => {
  const {
    policyData,
    isLoading: isLoadingPolicy,
    isError: isErrorPolicy,
    isFetching: isFetchingPolicy,
  } = usePolicy(policyNumber, { throwOnError });

  const {
    billingAccount,
    isLoading: isLoadingBilling,
    isError: isErrorBilling,
    isFetching: isFetchingBilling,
  } = useBillingAccount({ policyNumber }, { throwOnError });

  const {
    paymentOptions,
    isError: isErrorPaymentOptions,
    isLoading: isLoadingPaymentOptions,
    isFetching: isFetchingPaymentOptions,
  } = usePaymentOptions(
    {
      billingAccountNumber: policyData?.policy.billingAccountNumber,
      policySource: policyData?.policy.sourceSystemName,
    },
    !shouldMakePaymentOptionsCall(policyData),
    throwOnError,
  );

  const autopayEligible = getIsAutopayEligible(policyData, paymentOptions, billingAccount);

  const isError = isErrorPolicy || isErrorBilling || isErrorPaymentOptions;
  const isLoading = isLoadingPolicy || isLoadingBilling || isLoadingPaymentOptions;
  const isFetching = isFetchingBilling || isFetchingPaymentOptions || isFetchingPolicy;

  return {
    ...autopayEligible,
    isLoading,
    isError,
    isFetching,
  };
};
const ccIneligibleBillPlans: BillingPlan[] = ['TEN-PAY PLAN', 'FOUR-PAY PLAN'];
export const useIsAutopayEligibleForPaymentMethod = (
  policyNumber: string | undefined,
  paymentType: PaymentMethod | undefined,
  throwOnError = true,
): UseIsAutopayEligibleForPaymentMethodReturn => {
  const {
    isAutopayEligible,
    isLoading: isLoadingAutopayEligible,
    isError: isErrorAutopayEligible,
    isFetching: isFetchingAutopayEligible,
    ...autopayEligibleRest
  } = useIsAutopayEligible(policyNumber);

  const {
    billingAccount,
    isLoading: isLoadingBilling,
    isError: isErrorBilling,
    isFetching: isFetchingBillingAccount,
  } = useBillingAccount({ policyNumber }, { throwOnError });

  const isEligibleBillPlan =
    paymentType !== 'CC' || !ccIneligibleBillPlans.includes(billingAccount?.billingPlan);

  return {
    ...autopayEligibleRest,
    isAutopayEligible: isAutopayEligible && isEligibleBillPlan,
    isEligibleBillPlan,
    isLoading: isLoadingAutopayEligible || isLoadingBilling,
    isError: isErrorAutopayEligible || isErrorBilling,
    isFetching: isFetchingAutopayEligible || isFetchingBillingAccount,
  };
};

const getPaymentInfo = (
  policyData: UiPolicy | undefined,
  paymentOptions: GetPaymentOptionsSuccess | undefined,
  billingAccount: BillingResponse | undefined,
): GetPaymentInfoReturn => {
  let hasBalanceDue = undefined;
  if (billingAccount && billingAccount?.minimumDue !== undefined)
    hasBalanceDue = billingAccount?.minimumDue > 0;
  const canMakePayment = isPaymentOn(policyData, billingAccount, paymentOptions);

  return {
    ...getIsAutopayEligible(policyData, paymentOptions, billingAccount),
    canMakePayment,
    hasBalanceDue,
    isBillingAccountStatusInCancellation: isBillingAccountStatusInCancellation(billingAccount),
    policyNumber: policyData?.policy.policyNumber,
    isClassicBillingAccountStatusInPendingCancel: getClassicBillingAccountStatusInPendingCancel(
      policyData,
      billingAccount,
    ),
  };
};

export const usePaymentInfo = ({
  policyNumber,
  throwOnError = true,
}: {
  policyNumber: string | undefined;
  throwOnError?: boolean;
}): UsePaymentInfoReturn => {
  const {
    policyData,
    isLoading: isLoadingPolicy,
    isError: isErrorPolicy,
  } = usePolicy(policyNumber, { throwOnError });

  const {
    paymentOptions,
    isLoading: isLoadingPaymentOptions,
    isError: isErrorPaymentOptions,
  } = usePaymentOptions(
    {
      billingAccountNumber: policyData?.policy.billingAccountNumber,
      policySource: policyData?.policy.sourceSystemName,
    },
    !shouldMakePaymentOptionsCall(policyData),
    throwOnError,
  );

  const {
    billingAccount,
    isLoading: isLoadingBilling,
    isError: isErrorBilling,
  } = useBillingAccount({ policyNumber }, { throwOnError });

  const isLoading = isLoadingPolicy || isLoadingPaymentOptions || isLoadingBilling;
  const isError = isErrorPolicy || isErrorPaymentOptions || isErrorBilling;

  return {
    paymentInfo: getPaymentInfo(policyData, paymentOptions, billingAccount),
    isLoading,
    isError,
  };
};

export const usePaymentInfoForAllPolicies = (options?: {
  throwOnError?: boolean;
}): UsePaymentInfoForAllPoliciesReturn => {
  const { throwOnError = true } = options ?? {};
  const {
    policies,
    policyResults,
    isLoading: isLoadingPolicies,
    isError: isErrorPolicies,
  } = useUserPolicies({ throwOnPolicyError: throwOnError, throwOnUserError: throwOnError });
  const { accountResults } = useAllBillingAccounts({ throwOnError });

  const billingAccounts: GetPaymentMethodRequest[] = policies
    .filter(shouldMakePaymentOptionsCall)
    .map((policyData) => ({
      billingAccountNumber: policyData.policy.billingAccountNumber,
      policySource: policyData.policy.sourceSystemName,
    }));
  const { policiesPaymentsOptionsResults } = usePoliciesPaymentOptions(
    billingAccounts,
    throwOnError,
  );

  const paymentInfoResults: UsePaymentInfoReturn[] = useMemo(
    () =>
      (policyResults ?? [])?.map((policyResult) => {
        const billingAccountNumber = policyResult.policyData?.policy.billingAccountNumber;
        const paymentOptionsResult = policiesPaymentsOptionsResults.find(
          (options) => options.billingAccountNumber === billingAccountNumber,
        );

        const billingAccountResult = accountResults.find(
          (result) => result.billingAccountId === billingAccountNumber,
        );

        return {
          policyNumber: policyResult.policyNumber,
          paymentInfo: {
            ...getPaymentInfo(
              policyResult.policyData,
              paymentOptionsResult?.paymentOptions?.paymentMethods,
              billingAccountResult?.account,
            ),
            policyNumber: policyResult.policyNumber,
          },
          isError:
            (policyResult.isError ||
              billingAccountResult?.isError ||
              paymentOptionsResult?.isError) ??
            false,
          isLoading:
            (policyResult.isLoading ||
              billingAccountResult?.isLoading ||
              paymentOptionsResult?.isLoading) ??
            false,
        };
      }),
    [accountResults, policyResults, policiesPaymentsOptionsResults],
  );

  return useMemo(
    () => ({
      paymentInfos: paymentInfoResults.map((result) => result.paymentInfo),
      paymentInfoResults,
      isLoading: isLoadingPolicies || paymentInfoResults.some(({ isLoading }) => isLoading),
      isError: isErrorPolicies || paymentInfoResults.some(({ isError }) => isError),
    }),
    [isErrorPolicies, isLoadingPolicies, paymentInfoResults],
  );
};

const getAmountDueNDueDateInfo = (
  isDataLoading: boolean,
  billingAccount?: BillingResponse,
  isBillingAccountStatusInCancellation?: boolean,
  policyData?: UiPolicy,
  modalFlow?: ModalFlow,
  modalStep?: ModalStep,
  sharedState?: AutopayInformation | null,
): GetAmountDueNDueDateInfoReturn => {
  let dueDate, paymentAmount, paymentAmountDescription, dueDateDescription, error;
  if (isDataLoading)
    return { dueDate, paymentAmount, paymentAmountDescription, dueDateDescription, error };
  const isManageAutopay = modalFlow === 'autopaymanage';
  if (policyData && !billingAccount)
    error = 'Cannot get the payment amount and due date info, billingAccount is undefined';

  const eligiblePlansForPaymentFromState: BillingPlan[] = [
    'FOUR-PAY PLAN',
    'TEN-PAY PLAN',
    'TWELVE-PAY PLAN',
  ];

  let paymentFromState;
  if (eligiblePlansForPaymentFromState.includes(billingAccount?.billingPlan))
    paymentFromState = sharedState?.paymentAmountForEnrollmentConfirmation;

  switch (billingAccount?.billingPlan) {
    case 'ONE-PAY PLAN':
      if (
        !isManageAutopay &&
        billingAccount?.minimumDue !== undefined &&
        billingAccount?.minimumDue > 0
      ) {
        paymentAmount = billingAccount?.minimumDue;
        dueDate = billingAccount?.dueDate;
      } else if (
        isManageAutopay &&
        billingAccount?.minimumDue !== undefined &&
        billingAccount?.minimumDue > 0
      ) {
        if (!isBillingAccountStatusInCancellation) {
          paymentAmount = billingAccount?.nextDeductAmount;
          dueDate = billingAccount?.nextDeductDate;
        }
      } else {
        paymentAmountDescription = 'Will be sent prior to renewal';
        dueDateDescription = 'Will be sent prior to renewal';
      }
      break;
    case 'FOUR-PAY PLAN':
    case 'TEN-PAY PLAN':
    case 'TWELVE-PAY PLAN':
      if (
        (billingAccount?.minimumDue !== undefined && billingAccount?.minimumDue < 0) ||
        (billingAccount?.fullPayBalance !== undefined && billingAccount?.fullPayBalance < 0)
      )
        error = 'Minimum due or FullPayBalance is negative value';
      else {
        if (
          billingAccount?.minimumDue !== undefined &&
          billingAccount?.fullPayBalance !== undefined &&
          billingAccount?.minimumDue <= billingAccount?.fullPayBalance &&
          billingAccount?.minimumDue !== 0
        ) {
          if (isManageAutopay && !isBillingAccountStatusInCancellation) {
            paymentAmount = billingAccount?.nextDeductAmount;
            dueDate = billingAccount?.nextDeductDate;
          } else {
            paymentAmount = billingAccount?.minimumDue;
            dueDate = billingAccount?.dueDate;
          }
        } else if (billingAccount?.minimumDue === 0 && billingAccount?.fullPayBalance === 0) {
          paymentAmountDescription = 'Will be sent prior to renewal';
          dueDate = policyData?.policy.periodEndDate;
        } else if (
          billingAccount?.minimumDue !== undefined &&
          billingAccount?.fullPayBalance !== undefined &&
          billingAccount?.minimumDue < billingAccount?.fullPayBalance &&
          billingAccount?.minimumDue === 0
        ) {
          paymentAmountDescription = 'Available for review on the next billing statement';
          if (
            (modalFlow === 'autopaymanage' && modalStep === 'landing') ||
            (modalFlow === 'autopayenroll' && modalStep === 'confirmation') ||
            (modalFlow === 'pay' && modalStep === 'confirmation')
          ) {
            dueDateDescription = 'Available for review on the next billing statement';
          }
        } else
          error =
            'Caught an unsupported scenario when getting the payment amount and due date info';
      }

      if (
        paymentFromState != null &&
        paymentFromState !== '0.0' &&
        modalFlow === 'pay' &&
        modalStep === 'confirmation' &&
        billingAccount?.minimumDue &&
        billingAccount?.fullPayBalance &&
        billingAccount.minimumDue < billingAccount.fullPayBalance &&
        billingAccount?.minimumDue !== 0
      ) {
        const nextPaymentDt = sharedState?.nextPaymentDate ?? '';
        dueDate =
          new Date(nextPaymentDt).getFullYear() > 2000
            ? formatDate(nextPaymentDt, DateConstants.MONTH_FULL_NAME_LONG_FORMAT)
            : '';
        if (dueDate === '') dueDateDescription = 'Will be sent prior to renewal';
        paymentAmount = paymentFromState ? +paymentFromState : 0;
      }
      break;
  }

  return {
    paymentAmount,
    paymentAmountDescription,
    policyNumber: policyData?.policy.policyNumber,
    dueDate,
    dueDateDescription,
    error,
  };
};
export const useAmountDueNDueDateInfo = ({
  policyNumber,
  modalFlow,
  modalStep,
  throwOnError = true,
}: {
  policyNumber: string | undefined;
  modalFlow?: ModalFlow;
  modalStep?: ModalStep;
  throwOnError?: boolean;
}): UseAmountDueNDueDateInfoReturn => {
  const {
    billingAccount,
    isBillingAccountStatusInCancellation,
    isLoading: isLoadingBilling,
    isError: isErrorBilling,
    isFetching: isFetchingBilling,
  } = useBillingAccount({ policyNumber }, { throwOnError });

  const {
    policyData,
    isError: isErrorPolicy,
    isLoading: isLoadingPolicy,
    isFetching: isFetchingPolicy,
  } = usePolicy(policyNumber, { throwOnError });

  const [sharedState] = useSharedState<AutopayInformation>(
    `autopayEnroll-${policyData?.policy.policyNumber}`,
  );

  const isLoading = isLoadingPolicy || isLoadingBilling;

  if (modalStep === 'addcc' || modalStep === 'addeft')
    return {
      policyNumber,
      paymentAmount: undefined,
      paymentAmountDescription: undefined,
      dueDate: undefined,
      dueDateDescription: undefined,
      isLoading: false,
      isError: false,
      isFetching: false,
    };

  const { error: amountDueNDueDateInfoError, ...amountDueNDueDate } = getAmountDueNDueDateInfo(
    isLoading,
    billingAccount,
    isBillingAccountStatusInCancellation,
    policyData,
    modalFlow,
    modalStep,
    sharedState,
  );
  if (amountDueNDueDateInfoError)
    datadogLog({
      logType: 'error',
      message: amountDueNDueDateInfoError,
      context: {
        logOrigin: 'libs/features/servicing/shared/state/src/payment/state.ts',
        functionOrigin: 'useAmountDueNDueDateInfo',
      },
    });

  return {
    ...amountDueNDueDate,
    isLoading,
    isError: isErrorBilling || isErrorPolicy || !!amountDueNDueDateInfoError,
    isFetching: isFetchingBilling || isFetchingPolicy,
  };
};

export const usePaymentUrl = (
  request: GetPaymentMethodRequest,
  throwOnError = false,
): GetPaymentUrlResponse => {
  const enabled = !!request.billingAccountNumber;
  const queryKey = useMemo(
    () => [QUERY_KEYS.PAYMENT_URL, request.billingAccountNumber],
    [request.billingAccountNumber],
  );

  const { data, isLoading, isError, refetch, isFetching } = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      const { payload } = await getPaymentUrl(request);

      return payload.url;
    },
    // iframe url expires after used, so we can never reuse
    gcTime: 0,
    enabled,
    throwOnError,
  });

  return {
    isLoading,
    isError,
    url: data,
    refetch,
    isFetching,
  };
};

export const getMakePaymentClassicMutationKey = (policyNumber: string | undefined): string[] => [
  QUERY_KEYS.MAKE_PAYMENT_CLASSIC,
  policyNumber ?? '',
];

export const useMakePaymentWithNewMethod = (
  policyNumber?: string,
): PolicyPaymentResponse<
  MakePaymentWithNewMethodRequest,
  ResponseObject<MakePaymentWithExistingMethodSuccess, MakePaymentWithExistingMethodError>
> => {
  const mutationKey = getMakePaymentClassicMutationKey(policyNumber);
  const {
    mutateAsync,
    isPending: isSubmitting,
    isError,
  } = useMutation({
    mutationKey,
    mutationFn: async (request: MakePaymentWithNewMethodRequest) => {
      let makePaymentWithExistingMethodError: MakePaymentWithExistingMethodError | undefined =
        undefined;
      let success: MakePaymentWithExistingMethodSuccess | undefined;

      await makePaymentWithNewMethod(request)
        .then((res) => {
          success = res.payload;
        })
        .catch((error: ServicingRequestError) => {
          const errorCode = error.errorStack?.status.messages[0].code;
          switch (errorCode) {
            case 400018:
              makePaymentWithExistingMethodError = 'Invalid Policy or Billing Account';
              break;
            default:
              makePaymentWithExistingMethodError = 'unknown';
              break;
          }
          success = undefined;
        });

      const response: ResponseObject<
        MakePaymentWithExistingMethodSuccess,
        MakePaymentWithExistingMethodError
      > = {
        success,
        error: makePaymentWithExistingMethodError,
      };

      return response;
    },
  });

  return {
    makePayment: mutateAsync,
    isLoading: false,
    isError,
    isSubmitting,
  };
};
